import React, { useEffect, useMemo, useState } from "react";
import { Navigation, Pagination, FreeMode, Thumbs, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { appendImage, createImage, createMarkup } from "../../utils";
import axios from "axios";
import Layout from "../../components/Layout";
import FilmFestivalHeader from "../../components/FilmFestival/Header";
import { apiRoutes } from "../../constants/apiRoutes";
import { Link, navigate } from "gatsby";
import HeroSection from "../../components/FilmFestival/HeroSection";
import routes from "../../components/constants/routes";
// import { Helmet } from "react-helmet";
import Helmet from "../../components/Helmet";

const PastSeasons = ({ location }) => {
  const [pastSeasons, setPastSeasons] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [activeFilm, setActiveFilm] = useState(undefined);
  const [thumbsSwiper, setThumbsSwiper] = useState();
  const [activeBlogId, setActiveBlogId] = useState(0);

  const films = selectedEvent?.attributes?.flims?.data;
  const params = new URLSearchParams(location.search);
  const blogId = params.get("id");

  useEffect(() => {
    if (blogId) {
      setActiveBlogId(blogId);
    }
  }, [blogId]);

  const fetchPastSeasons = async () => {
    try {
      const { data } = await axios.get(apiRoutes.filmFestivalPastSeasons);
      console.log(data, "data");
      if (data) {
        setPastSeasons(data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchPastSeasons();
  }, []);

  useMemo(() => {
    if (pastSeasons.length) {
      const activeEvent = pastSeasons.find((el) => el.id == activeBlogId);
      if (activeEvent) {
        setSelectedEvent(activeEvent);
        setActiveFilm(activeEvent.attributes.flims?.data);
      } else {
        setSelectedEvent(pastSeasons[0]);
        setActiveFilm(pastSeasons[0].attributes.flims?.data);
      }
    }
  }, [pastSeasons, activeBlogId]);

  React.useEffect(() => {
    let counter = 0;
    const timer = setInterval(() => {
      const header = document.querySelector(".biffl-list-dropdown");
      counter++;
      if (header) {
        clearInterval(timer);
        window.addEventListener("scroll", function () {
          if (window.pageYOffset >= 420) {
            header.classList.add("biffl-dropdown-fixed");
            document.body.style.transition = "all 0.4s";
          } else {
            header.classList.remove("biffl-dropdown-fixed");
          }
        });
      } else if (counter >= 100) {
        clearInterval(timer);
      }
    }, 100);
  }, []);

  useEffect(() => {
    appendImage("rich-editor");
  }, [films]);

  const seoData = {
    title: "Past screenings Highlights | Film Festivals Luxembourg",
    description:
      "Relive the highlights of Past screenings at Film Festivals Luxembourg. Explore the memorable movies, events, and discussions that made our festivals unforgettable.",
    url: `${process.env.GATSBY_SITE_URL}${routes.FFPastSeason}/`,
  };

  return (
    <Layout>
      <>
        <Helmet
          title={seoData.title}
          description={seoData.description}
          url={seoData?.url}
        />

        <div className="film-festival">
          <div className="container-fluid padding-0">
            <div className="row">
              <div className="col-12 pr-mob-0">
                <HeroSection />
                <div className="head-tab mt-40" id="tab">
                  <FilmFestivalHeader />

                  {Object.values(selectedEvent)?.length ? (
                    <div className="tab-content" id="nav-tabContent">
                      <div className="film-past-seasons">
                        <div className="past-season">
                          <div className="container-fluid" id="past-season">
                            <div className="row">
                              <div className="col-md-4 col-lg-2">
                                <div className="biffl-list">
                                  <ul>
                                    {pastSeasons?.map((el, index) => {
                                      return (
                                        <li
                                          onClick={() => {
                                            navigate(
                                              `${routes.FFPastSeason}?id=${el.id}#tab`
                                            );
                                            setSelectedEvent(el);
                                          }}
                                          className={
                                            selectedEvent?.id === el.id
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <Link to="#tab">
                                            {el?.attributes?.menu_title}
                                          </Link>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>

                                <div className="biffl-list-dropdown">
                                  <div className="dropdown">
                                    <Link
                                      className="btn dropdown-toggle"
                                      to="#"
                                      role="button"
                                      id="dropdownMenuLink"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      {selectedEvent?.attributes?.menu_title}
                                    </Link>

                                    <ul
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuLink"
                                    >
                                      {pastSeasons?.map((el, index) => {
                                        return (
                                          <li
                                            onClick={() => setSelectedEvent(el)}
                                          >
                                            {el?.attributes?.menu_title}
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8 col-lg-10">
                                <div className="past-seasion-slider">
                                  <h2 className="past-seasion-title mt-60">
                                    {selectedEvent?.attributes?.title}
                                  </h2>
                                  <Swiper
                                    onSwiper={setThumbsSwiper}
                                    spaceBetween={20}
                                    // slidesPerView={12}
                                    // freeMode={true}
                                    // loop={true}
                                    // centeredSlides={true}
                                    watchSlidesProgress={true}
                                    modules={[
                                      // FreeMode,
                                      Navigation,
                                      Pagination,
                                      Thumbs,
                                      // Autoplay,
                                    ]}
                                    className="mySwiper"
                                    navigation
                                    pagination={{ clickable: true }}
                                    autoplay={{
                                      delay: 10000,
                                      disableOnInteraction: false,
                                    }}
                                    breakpoints={{
                                      320: {
                                        slidesPerView: 3,
                                        spaceBetween: 20,
                                      },
                                      768: {
                                        slidesPerView: 4,
                                        spaceBetween: 20,
                                        // centeredSlides: true
                                      },

                                      1300: {
                                        slidesPerView: 12,
                                      },
                                      1920: {
                                        slidesPerView: 12,
                                        spaceBetween: 20,
                                      },
                                    }}
                                  >
                                    {films?.map((el, index) => {
                                      return (
                                        <SwiperSlide>
                                          <img
                                            onClick={() => setActiveFilm(el)}
                                            src={createImage(
                                              el.attributes.image.data
                                                .attributes.url
                                            )}
                                            alt={el.attributes?.image_alt_text}
                                            width={"auto"}
                                            height={"auto"}
                                          />
                                        </SwiperSlide>
                                      );
                                    })}
                                  </Swiper>
                                  <div className="programme">
                                    {/* <h2>
                                      {
                                        selectedEvent?.attributes?.description
                                          ?.title
                                      }
                                    </h2> */}
                                    <div
                                      className="rich-editor"
                                      dangerouslySetInnerHTML={createMarkup(
                                        selectedEvent?.attributes?.description
                                          ?.description
                                      )}
                                    ></div>
                                  </div>
                                  <Swiper
                                    spaceBetween={20}
                                    navigation={true}
                                    centeredSlides={true}
                                    thumbs={{ swiper: thumbsSwiper }}
                                    autoHeight={true}
                                    modules={[
                                      FreeMode,
                                      Navigation,
                                      Thumbs,
                                      // Autoplay,
                                    ]}
                                    // autoplay={{
                                    //   delay: 10000,
                                    //   disableOnInteraction: false,
                                    // }}
                                    className="mySwiper2"
                                  >
                                    {films?.map((el, index) => {
                                      const active = el.attributes;
                                      return (
                                        <SwiperSlide>
                                          {activeFilm ? (
                                            <div className="slider-display">
                                              <div className="row">
                                                <div className="col-md-12 col-lg-4">
                                                  <div className="slider-display-col">
                                                    <img
                                                      src={createImage(
                                                        active?.image?.data
                                                          .attributes.url
                                                      )}
                                                      alt={
                                                        active?.image_alt_text
                                                      }
                                                      width={"auto"}
                                                      height={"auto"}
                                                    />
                                                  </div>
                                                  {active?.trailer_url ? (
                                                    <div className="mt-10 mb-20">
                                                      {" "}
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height="30"
                                                        viewBox="0 -960 960 960"
                                                        width="30"
                                                      >
                                                        <path d="m383-310 267-170-267-170v340Zm97 230q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" />
                                                      </svg>
                                                      <Link
                                                        href={
                                                          active?.trailer_url
                                                        }
                                                        className="view-trailor font-weight-500"
                                                        target="_blank"
                                                      >
                                                        Click here to view the
                                                        Trailer
                                                      </Link>{" "}
                                                    </div>
                                                  ) : null}
                                                </div>
                                                <div className="col-md-12 col-lg-8">
                                                  {!active?.use_ckeditor ? (
                                                    <div className="ff-presents-movies">
                                                      <h2>{active?.title}</h2>

                                                      {active?.date ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            Date:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            {active?.date}
                                                          </span>
                                                        </div>
                                                      ) : null}

                                                      {active?.time ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            Time:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            {active?.time}
                                                          </span>
                                                        </div>
                                                      ) : null}

                                                      {active?.venue ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            Venue:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            {active?.venue}
                                                          </span>
                                                        </div>
                                                      ) : null}

                                                      {active?.country ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            Country:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            {active?.country}
                                                          </span>
                                                        </div>
                                                      ) : null}

                                                      {active?.genre ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            Genre:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            {active?.genre}
                                                          </span>
                                                        </div>
                                                      ) : null}

                                                      {active?.duration ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            Duration:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            {active?.duration}
                                                          </span>
                                                        </div>
                                                      ) : null}

                                                      {active?.directed_by ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            Directed by:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            {
                                                              active?.directed_by
                                                            }
                                                          </span>
                                                        </div>
                                                      ) : null}

                                                      {active?.starring ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            Starring:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            {active?.starring}
                                                          </span>
                                                        </div>
                                                      ) : null}

                                                      {active?.synopsis ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            Synopsis:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            {active?.synopsis}
                                                          </span>
                                                        </div>
                                                      ) : null}

                                                      {active?.about ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            About:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            {active?.about}
                                                          </span>
                                                        </div>
                                                      ) : null}

                                                      {active?.price ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            Price:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            {active?.price}
                                                          </span>
                                                        </div>
                                                      ) : null}

                                                      {active?.tickets ? (
                                                        <div className="row mb-2">
                                                          <span className="col-md-2 col-lg-3 w-30 font-weight-800">
                                                            Tickets:
                                                          </span>
                                                          <span className="col-md-10 col-lg-9 w-70">
                                                            <Link
                                                              to={
                                                                active?.tickets
                                                              }
                                                              target="_blank"
                                                            >
                                                              {" "}
                                                              {active?.tickets}
                                                            </Link>
                                                          </span>
                                                        </div>
                                                      ) : null}
                                                    </div>
                                                  ) : (
                                                    <div className="ff-presents-movies">
                                                      <h2>{active?.title}</h2>
                                                      <div
                                                        className="ml-14 rich-editor"
                                                        dangerouslySetInnerHTML={createMarkup(
                                                          active?.description
                                                        )}
                                                      ></div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                        </SwiperSlide>
                                      );
                                    })}
                                  </Swiper>
                                </div>

                                {/* <div className="programme">
                                  <h2>
                                    {
                                      selectedEvent?.attributes?.description
                                        ?.title
                                    }
                                  </h2>
                                  <div
                                    className="rich-editor"
                                    dangerouslySetInnerHTML={createMarkup(
                                      selectedEvent?.attributes?.description
                                        ?.description
                                    )}
                                  ></div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p className="text-center" style={{ margin: "15em 0" }}></p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default PastSeasons;
